export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "welcomeOnRema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur REMA LEARNING ADMIN"])},
  "theFirstSocialMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 1er réseau professionnel dédié aux personnels de santé exerçant en Afrique"])},
  "connectYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous à votre compte REMA"])},
  "loginButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONNEXION"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "errorLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ou mot de passe incorrect"])},
  "errorPasswordValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un mot de passe correct (min: 8 caractères)"])},
  "errorEmailValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email non valide"])},
  "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
  "formationMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formations"])},
  "webinarMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webinaires"])},
  "attestationMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attestations"])},
  "certificateMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificats"])},
  "campaignMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagnes"])},
  "jobMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])},
  "clinicalCaseMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cas cliniques"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "programWebinarButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmer un webinaire"])},
  "webinarTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre du webinaire"])},
  "creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création"])},
  "isGoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déroulement"])},
  "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût"])},
  "participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "continuousButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "webinarLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien du webinaire"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "coverPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image de couverture"])},
  "recommendedSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille recommandé :  517 x 300"])},
  "costInCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût (<span style='color: #909090;'>en crédits</span>)"])},
  "dateAndHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure"])},
  "animator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animateur(s)"])},
  "attach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce jointe"])},
  "concernedProfession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professions concernées"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenus"])},
  "successAttestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attestation de succès"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
  "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure"])},
  "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déroulement"])},
  "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag(s)"])},
  "linkError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un lien valide"])},
  "fillAllFieldError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir tous les champs"])},
  "publishWebinar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier le webinaire"])},
  "goingToPublishWebinar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de publier un webinaire. Il sera rendu disponible aux utilisateurs si vous continuer"])},
  "goingToUpdateWebinar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de modifier un webinaire. Il sera rendu disponible aux utilisateurs si vous continuer"])},
  "publishButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier"])},
  "updateButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "joinVideoButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre  la vidéo"])},
  "certificateOverviewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu de l’attestation"])},
  "webinar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webinaire"])},
  "clickToAddVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquer pour ajouter une vidéo"])},
  "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
  "addQuizButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter le questionnaire"])},
  "updateQuizButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le questionnaire"])},
  "publishNowButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier maintenant"])},
  "evaluationQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire d’évaluation"])},
  "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
  "successAddQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre questionnaire d'évaluation a été ajouté avec succès."])},
  "successButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "noWebinarAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun webinaire ajouté"])},
  "webinarAddedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre webinaire a été programmé avec succès."])},
  "webinarVideoLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien de la vidéo"])},
  "linkAddedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien de la vidéo du webinaire ajouté avec succès."])},
  "noParticipantAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun participant à ce webinaire"])},
  "replayAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replay disponible"])},
  "numberAttestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nbre attest."])},
  "numberParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particip."])},
  "whatWantDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que voulez-vous faire"])},
  "replay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir replay"])},
  "changeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer lien"])},
  "attachUpdatedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce(s) jointe(s) du webinaire modifiée(s) avec succès."])},
  "noFormationAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune formation ajoutée"])},
  "formationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la formation"])},
  "modules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modules"])},
  "createFormationButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une formation"])},
  "addFormation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une formation"])},
  "formationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre (72 caractères)"])},
  "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu (256 caractères)"])},
  "audioCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cours audios"])},
  "videoCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cours vidéos"])},
  "joinPieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièces jointes"])},
  "EndCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat de fin"])},
  "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formateur(s)"])},
  "prerequisite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prérequis"])},
  "objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectifs/Compétences visés"])},
  "wantDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer ce webinaire ?"])},
  "deleteWebinar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un webinaire"])},
  "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom & Prénom(s)"])},
  "numberDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nbre Téléchargement"])},
  "noDownloadedAttestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune attestation téléchargée pour ce webinaire"])},
  "noAttestationAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune attestation générée"])},
  "addAttestationButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer une attestation"])},
  "namePerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom & Prénom(s)"])},
  "emissionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'émission"])},
  "generateButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
  "generateAttestationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attestation générée avec succès"])},
  "generateCertificateButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer un certificat"])},
  "formationTitles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre de la formation"])},
  "noCertificateAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun certificat généré"])},
  "numberHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure totale de la formation"])},
  "generateCertificateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat généré avec succès"])},
  "totalHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure totale"])},
  "createCampaignButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une campagne"])},
  "noCampaignAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune nouvelle campagne créée"])},
  "campaignName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé"])},
  "audience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audience"])},
  "beginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrage"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin"])},
  "startButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer"])},
  "campaignTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre de la campagne"])},
  "receivers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataires"])},
  "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouton CTA"])},
  "buttonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre du bouton"])},
  "buttonLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien"])},
  "diffusionList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de diffusion"])},
  "remaUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs REMA"])},
  "excelFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier Excel"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
  "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "addAddressList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléverser la liste d’adresse"])},
  "clickAddVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour ajouter la vidéo"])},
  "fileFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format .xls .excel"])},
  "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
  "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche d'âge"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "professionalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut professionnel"])},
  "specialities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécialités"])},
  "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badge"])},
  "minFollowNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de following"])},
  "lastLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière connexion"])},
  "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETUDIANT"])},
  "nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFIRMIER"])},
  "midwife": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAGE-FEMME"])},
  "practitionerGeneralist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEDECIN GENERALISTE"])},
  "doctorInSpecialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEDECIN EN SPECIALISATION"])},
  "specialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPECIALISTE"])},
  "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENSEIGNANT"])},
  "listName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé la liste"])},
  "saveDiffusionList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer cette liste de diffusion"])},
  "campaignLaunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancement de la campagne"])},
  "savedCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagne enregistrée"])},
  "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
  "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
  "campaignObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objet du mail"])},
  "clickAddFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour ajouter le fichier"])},
  "createJobButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une offre d'emploi"])},
  "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offres d’emploi"])},
  "jobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre de l’emploi"])},
  "employer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employeur"])},
  "interactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interactions"])},
  "noJobsAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune offre d'emploi ajoutée"])},
  "newJobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle offre d’emploi"])},
  "addCandidateLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Inclure un lien de candidature : "])},
  "jobLocalisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation du poste"])},
  "eligibleLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveaux académiques éligibles"])},
  "candidateInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruction de candidature"])},
  "candidatureLimitDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date limite de candidature"])},
  "contractType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de contrat"])},
  "workMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de travail"])},
  "workHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaire de travail"])},
  "offerCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie de l’offre"])},
  "recommendTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags de recommandation ciblée"])},
  "employerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l’employeur"])},
  "pictureSee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images aperçu (logo, affiche, etc)"])},
  "recommendSizeJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille recommandée : 650 x 300"])},
  "addNewEmployer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un employeur"])},
  "nameOfEmployer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "addNewCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une catégorie"])},
  "deleteJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un job"])},
  "wantDeleteJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer cette offre d'emploi ?"])},
  "noClinicalCaseAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun cas clinique ajouté"])},
  "clinicalCaseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre du cas clinique"])},
  "programClinicalCaseButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmer un cas clinique"])},
  "clinicalCaseLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien du cas clinique"])},
  "clinicalCaseAddedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre cas clinique a été programmé avec succès."])},
  "publishClinicalCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier le cas clinique"])},
  "goingToPublishClinicalCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de publier un cas clinique. Il sera rendu disponible aux utilisateurs si vous continuer"])},
  "goingToUpdateClinicalCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de modifier un cas clinique. Il sera rendu disponible aux utilisateurs si vous continuer"])},
  "wantDeleteClinicalCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer ce cas clinique ?"])},
  "deleteClinicalCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un cas clinique"])},
  "clinicalCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cas clinique"])},
  "clinicalCaseAttachUpdatedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce(s) jointe(s) du cas clinique modifiée(s) avec succès."])},
  "clinicalCaseLinkAddedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien de la vidéo du cas clinique ajouté avec succès."])},
  "noClinicalCaseParticipantAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun participant à ce cas clinique"])}
}