import { createRouter, createWebHistory } from "vue-router";
import pathLoader from "../plugins/loader";
import { getAuth, onAuthStateChanged } from "firebase/auth";
const routes = [
  {
    path: "/login",
    name: "Login",
    component: pathLoader("login", "index"),
  },
  {
    path: "/",
    name: "Home",
    component: pathLoader("home", "index"),
    redirect: "/formations",
    children: [
      {
        path: "/formations",
        name: "Formations",
        component: pathLoader("formations", "index"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/webinar",
        name: "Webinar",
        component: pathLoader("webinar", "index"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/attestation",
        name: "Attestation",
        component: pathLoader("attestation", "index"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/certificate",
        name: "Certificate",
        component: pathLoader("certificate", "index"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/campaign",
        name: "Campaign",
        component: pathLoader("campaign", "index"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/jobs",
        name: "Jobs",
        component: pathLoader("jobs", "index"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/clinical-case",
        name: "ClinicalCase",
        component: pathLoader("clinicalCase", "index"),
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // const uid = user.uid
        next();
      } else {
        next({
          path: "/login",
        });
      }
    });
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
